@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #fff;
}

.main-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: radial-gradient(
    78.58% 78.58% at 50% 74.72%,
    #883b1b 0%,
    #421d1b 23.44%,
    #000000 64.58%,
    #000000 100%
  );
}
.main-container > main {
  z-index: 3;
}
