.footer {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  background: #000;
  width: 100%;
  padding: 10px;
  padding-bottom: 24px;
  z-index: 3;
  position: relative;
}

.quote {
  color: #999;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}
.inner {
  padding: 16px 16px 0 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 180px;
  padding: 0 20px;
  margin-bottom: 10px;
  border-right: 1px solid #ffffff;
}
.item:last-child {
  border: none;
}
.itemName {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ebb057;
}
.itemValue {
  font-weight: 400;
  font-size: 40px;
  line-height: 66px;
}

@media (max-width: 1100px) {
  .footer {
    padding-bottom: 10px;
  }
  .item {
    min-width: 120px;
    padding: 0 16px;
  }
  .itemName {
    font-size: 14px;
    line-height: 20px;
  }
  .itemValue {
    font-size: 24px;
    line-height: 48px;
  }
}
@media (max-width: 768px) {
  .quote {
    font-size: 16px;
    line-height: 24px;
  }
  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .itemValue {
    font-size: 20px;
    line-height: 36px;
  }
  .item {
    min-width: 80px;
  }
  .item:nth-child(3) {
    border: none;
  }
}
@media (max-width: 578px) {
  .itemName {
    font-size: 12px;
    line-height: 16px;
  }
}
