.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 42px 64px;
  z-index: 3;
}
.header > span {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.actions {
  display: flex;
  align-items: center;
}
.actions span {
  width: 105px;
}
.actions button {
  background: transparent;
  border: 0;
  width: 32px;
  height: 32px;
  margin: 0 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header {
    padding: 16px 24px;
  }
  .heade > span {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 578px) {
  .header {
    padding: 16px;
  }
  .header > span {
    font-size: 20px;
    line-height: 28px;
  }
}
