.container > svg {
  position: absolute;
  bottom: 220px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
@keyframes cash-fall {
  0% {
    top: -20%;
  }
  100% {
    top: 100%;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  40% {
    opacity: 1;
    transform: translateY(0px);
  }
  79% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
@keyframes flame-movement1 {
  0% {
    opacity: 1;
    transform: scale(0.9, 1) translate(0, 20px) rotate(-3deg);
  }
  50% {
    opacity: 1;
    transform: scale(0.95, 1) translate(-5px, 0) rotate(3deg);
  }
  100% {
    opacity: 1;
    transform: scale(0.9, 1) translate(0, 20px) rotate(-3deg);
  }
}
@keyframes flame-movement2 {
  0% {
    opacity: 1;
    transform: scale(0.9, 1) translate(5px, 0px) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(0.95, 1) translate(10px, 15px) rotate(-3deg);
  }
  100% {
    opacity: 1;
    transform: scale(0.9, 1) translate(5px, 0px) rotate(0deg);
  }
}

@keyframes cash-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
}

@keyframes cash-fall {
  0% {
    top: -20%;
  }
  100% {
    top: 100%;
  }
}

@keyframes cash-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
}

.cash {
  position: fixed;
  top: -20%;
  z-index: 1;
  user-select: none;
  animation-duration: 10s, 4s;
  animation-name: cash-fall, cash-shake;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.cash.slow {
  animation-duration: 20s, 4s;
}
.cash.normal {
  animation-duration: 10s, 4s;
}
.cash.fast {
  animation-duration: 4s, 4s;
}

.cash:nth-of-type(1) {
  left: 10%;
  animation-delay: 2s, 1s;
}

.cash:nth-of-type(2) {
  left: 15%;
  animation-delay: 8s, 0.5s;
}

.cash:nth-of-type(3) {
  left: 20%;
  animation-delay: 14s, 2s;
}

.cash:nth-of-type(4) {
  left: 25%;
  animation-delay: 2s, 2.5s;
}

.cash:nth-of-type(5) {
  left: 30%;
  animation-delay: 7s, 3s;
}

.cash:nth-of-type(6) {
  left: 35%;
  animation-delay: 1s, 2s;
}

.cash:nth-of-type(7) {
  left: 40%;
  animation-delay: 15s, 1s;
}

.cash:nth-of-type(8) {
  left: 45%;
  animation-delay: 3s, 0s;
}

.cash:nth-of-type(9) {
  left: 60%;
  animation-delay: 5s, 1.5s;
}

.cash:nth-of-type(10) {
  left: 65%;
  animation-delay: 149s, 0s;
}

.cash:nth-of-type(11) {
  left: 70%;
  animation-delay: 2s, 2.5s;
}
.cash:nth-of-type(12) {
  left: 75%;
  animation-delay: 13s, 3s;
}
.fire > path {
  animation-name: fade-in-out;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.fire .flame1,
.fire .flame4 {
  animation-name: flame-movement1;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
.fire .flame2,
.fire .flame3,
.fire .flame5 {
  animation-name: flame-movement2;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
@media (max-width: 578px) {
  .container > svg {
    bottom: calc(250px - 10%);
    width: 90%;
    left: 50%;
    transform: translateX(-48%);
    z-index: 2;
  }
  .cash img {
    transform: scale(0.7);
  }
}
