.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}
.spinner {
  pointer-events: none;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-color: white;
  border-top-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}
