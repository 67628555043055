.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: radial-gradient(
    78.58% 78.58% at 50% 74.72%,
    #883b1b 0%,
    #421d1b 23.44%,
    #000000 64.58%,
    #000000 100%
  );
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner p {
  margin-top: 16px;
  font-size: 20px;
  text-align: center;
}
.inner img {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-top: 16px;
  font-size: 20px;
}
