.details {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
}

.container > h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  text-align: center;
  margin-bottom: 14px;
}
.container > p {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  margin-top: 14px;
  padding: 0 10px;
}
.container > p > span {
  display: inline-block;
  width: 36px;
}

@media (max-width: 578px) {
  .container > h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 6px;
  }
  .container > p {
    font-size: 16px;
    line-height: 20px;
  }
  .details {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  .container > p > span {
    width: 24px;
  }
}
