.statisticsBox {
  display: flex;
  flex-direction: column;
}
.statisticsBoxInner {
  display: flex;
  justify-content: center;
}
.statisticsBoxInner > span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  font-weight: 500;
  font-size: 78px;
  line-height: 78px;
  width: 75px;
  height: 95px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(34px);
  border-radius: 6px;
}
.statisticsBoxInner.small > span {
  width: 22px;
  height: 28px;
  font-size: 23px;
  line-height: 23px;
  margin-right: 4px;
  border-radius: 2px;
}

.statisticsBoxInner span.statisticsSign {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  backdrop-filter: none;
  width: auto;
}
.statisticsBoxInner span.statisticsDivider {
  background: transparent;
  backdrop-filter: none;
  width: auto;
}

.statisticsTitle {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
}
.statisticsTitle > span {
  color: #999;
}

@media (max-width: 1000px) {
  .statisticsBoxInner > span {
    margin-right: 10px;
    font-size: 36px;
    line-height: 42px;
    width: 42px;
    height: 52px;
  }
}
@media (max-width: 578px) {
  .statisticsBoxInner > span {
    margin-right: 4px;
    font-size: 24px;
    line-height: 36px;
    width: 30px;
    height: 38px;
  }
  .statisticsTitle.small {
    font-size: 13px;
    line-height: 20px;
  }
  .statisticsBoxInner.small > span {
    width: 16px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    margin-right: 2px;
    border-radius: 2px;
  }
  .statisticsBoxInner span.statisticsSign,
  .statisticsBoxInner span.statisticsDivider {
    width: auto;
  }
}
